import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Button, CardActionArea } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import useStyles from '../styles/styles';
import { useTranslation } from "react-i18next";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import backImage from '../assets/img/back.png';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { colors } from './Theme/WebTheme';
const Section = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const categorylistdata = useSelector(state => state.categorylistdata);
  const [data, setData] = useState([]);
  const auth = useSelector(state => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);

  const role = props.role;
  
  useEffect(() => {
    if (categorylistdata.categories) {
      setData(categorylistdata.categories);
    } else {
      setData([]);
    }
  }, [categorylistdata.categories]);

  useEffect(() => {
    if (auth.profile && auth.profile.uid) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.profile]);

  const navigate = useNavigate();

  const sectionItems = [
    {
      id: 1,
      icon: <EmojiTransportationIcon style={{ color:colors.GOLD, fontSize: '30px', marginRight: 5 }} />,
      sentence1: t('pruduct_section_heading_1'),
      sentence: t('product_section_1'),
    },
    {
      id: 2,
      icon: <AccessTimeIcon style={{ color:colors.GOLD, fontSize: '30px', marginRight: 8 }} />,
      sentence1: t('pruduct_section_heading_2'),
      sentence: t('product_section_2'),
    },
  ];
  const sectionItems1 = [
    {
      id: 1,
      icon: <CarCrashOutlinedIcon style={{ color:colors.GOLD, fontSize: '30px', marginRight: 8 }} />,
      sentence1: t('pruduct_section_heading_3'),
      sentence: t('product_section_3'),
    },
    {
      id: 2,
      icon: <VerifiedIcon style={{ color:colors.GOLD, fontSize: '30px', marginRight: 8 }} />,
      sentence1: t('pruduct_section_heading_4'),
      sentence: t('product_section_4'),
    },
  ];

  const Boxs = () => {
    return (
      <Box sx={{ flexGrow: 1, minHeight: data.length > 0 ? '230px' : '160px', backgroundColor:colors.DARKWHITE, padding: 3, direction: isRTL === 'rtl'? 'rtl':'ltr' }}>
        {data && data.length > 0 ?
          <div>
            <Typography component="div" variant="h3" fontWeight={350} style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold' }}>
              {t('our_services')}
            </Typography>
            <Grid container className={classes.sectionGridContainer} >
              {data.map((item, key) => {
                return (
                   item.active === true ?
                  <div key={key} style={{ textAlign: 'center' }}>
                    
                    <Card sx={{ display: 'flex', maxWidth: 100, maxHeight: 100, height: 100, m: 4, boxShadow: 5, borderRadius: 50 }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        sx={{ width: 100, height: 50, marginLeft: 1,}}
                        image={item.cat_image}
                        alt="Live from space album cover"
                      />
                      </CardActionArea>
                    </Card>
                    <Typography  component="div" variant="h5" style={{ textTransform: 'uppercase' }}>
                      {item.category}
                    </Typography>
                  </div>
                  : null
                )
              })}
            </Grid>
            <Grid container className={classes.sectionGridContainer}>
              <Box
                xs={12}
                md={3.5}
                minHeight={150}
                className={classes.sectionGridItem2}
                textAlign={'center'}
              >
                <Typography component="div" style={{ marginTop: 18, padding: 25, fontSize: 18, textAlign: isRTL?'right':'left' }}>
                  {t('category_description')}
                </Typography>
                <Button
                  variant="contained"
                  color='info'
                  sx={{ width: '220px', height: '60px', fontSize: '20px', marginBottom: 2, borderRadius: 35, backgroundColor:colors.MAIN_COLOR, alignSelf: 'center' }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (loggedIn) {
                      navigate((role && role === 'driver') ? '/bookings' : '/addbookings')
                    } else {
                      navigate('/login')
                    }
                  }}
                >
                  {role === 'driver' ? t('info') : t('book_now')}
                </Button>
              </Box>
            </Grid>

          </div>
          :
          <Typography variant="h3" fontWeight={400} style={{ textAlign: 'center', padding: 35, color: colors.BLACK }}>{t('service_start_soon')}</Typography>
        }
      </Box>
    )
  }

  return (
    <div>
      <Boxs />

      <Box sx={{ flexGrow: 1, minHeight: '350px', backgroundImage: `url(${backImage})`, backgroundSize: 'cover' }}>
        <Typography variant="h3" fontWeight={400} style={{ color:colors.GOLD, textAlign: 'center', padding: 35 }}>{t('best_service_provider')}</Typography>
        <Grid container className={classes.sectionGridContainer}>
          {sectionItems.map((item, key) => {
            return (
              <Box
                key={key}
                xs={12}
                md={3.5}
                minHeight={150}
                className={classes.sectionGridItem1}
                style={{direction: isRTL==='rtl'? 'rtl':'ltr'}}
              >
                <div style={{ display: 'flex' }}>
                  {item.icon}
                  <div>
                    <Typography variant="h5" style={{ color:colors.GOLD, fontWeight: 'bold' }}>{item.sentence1}</Typography>
                    <Typography style={{ color:colors.WHITE}} className={classes.aboutUsSubtitle}>{item.sentence}</Typography>
                  </div>
                </div>
              </Box>
            )
          })}
        </Grid>

        <Grid container className={classes.sectionGridContainer}>
          {sectionItems1.map((item, key) => {
            return (
              <Box
                key={key}
                xs={12}
                md={3.5}
                minHeight={150}
                style={{direction: isRTL==='rtl'? 'rtl':'ltr'}}
                className={classes.sectionGridItem1}
              >
                <div style={{ display: 'flex' }}>
                  {item.icon}
                  <div>
                    <Typography variant="h5" style={{ color:colors.GOLD, fontWeight: 'bold' }}>{item.sentence1}</Typography>
                    <Typography style={{ color:colors.WHITE}} className={classes.aboutUsSubtitle}>{item.sentence}</Typography>
                  </div>
                </div>
              </Box>
            )
          })}
        </Grid>
      </Box>
    </div>
  );
};

export default Section;