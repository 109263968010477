import {
    FETCH_ZONES,
    FETCH_ZONES_SUCCESS,
    FETCH_ZONES_FAILED,
    EDIT_ZONE
  } from "../store/types";
  
  const INITIAL_STATE = {
    zones: null,
    loading: false,
    error: {
      flag: false,
      msg: null
    }
  }
  
  export const zonelistreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_ZONES:
        return {
          ...state,
          loading: true
        };
      case FETCH_ZONES_SUCCESS:
        return {
          ...state,
          zones: action.payload,
          loading: false
        };
      case FETCH_ZONES_FAILED:
        return {
          ...state,
          zones: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload
          }
        };
      case EDIT_ZONE:
        return state;
      default:
        return state;
    }
  };