export const colors = {
    DARKWHITE: '#eeeeee',
    WHITE: '#fff',
    BLACK: '#000',
    RED: "#ff1e1d",
    GREEN: "#49BF00",
    MAIN_COLOR: "#151824",
    SECONDARY: "#6b728e",
    THIRDCOLOR: "#c2c9d1",
    BACKGROUND: "rgba(22,22,22,0.8)",
    GOLD:"gold"
  }