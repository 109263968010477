import {
  FETCH_ADDMONEY,
  FETCH_ADDMONEY_SUCCESS,
  FETCH_ADDMONEY_FAILED,
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, push } from "firebase/database";

  export const fetchAddMoney = () => (dispatch) => {
  
    const {
      addMoneyRef
    } = firebase;
  
    dispatch({
      type: FETCH_ADDMONEY,
      payload: null
    });

    onValue(addMoneyRef, snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          return data[i]
        });
        dispatch({
          type: FETCH_ADDMONEY_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_ADDMONEY_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_addmoney
        });
      }
    });
  };

  export const addMoney = (addmoney) => async (dispatch) => {
    const {
      addMoneyRef
    } = firebase;

    dispatch({
      type: FETCH_ADDMONEY,
      payload: {addmoney }
    });
    push(addMoneyRef, addmoney);
  }
