import {
    FETCH_COUNTRIES,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_COUNTRIES_FAILED,
    FETCH_COUNTRIES_STRING_SUCCESS,
    EDIT_COUNTRY
  } from "../store/types";
  
  const INITIAL_STATE = {
    countries: null,
    restrictedCountries: null,
    loading: false,
    error: {
      flag: false,
      msg: null
    }
  }
  
  export const countrylistreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_COUNTRIES:
        return {
          ...state,
          loading: true
        };
      case FETCH_COUNTRIES_SUCCESS:
        return {
          ...state,
          countries: action.payload,
          loading: false
        };
      case FETCH_COUNTRIES_STRING_SUCCESS:
        return {
          ...state,
          restrictedCountries: action.payload
        };        
      case FETCH_COUNTRIES_FAILED:
        return {
          ...state,
          countries: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload
          }
        };
      case EDIT_COUNTRY:
        return state;
      default:
        return state;
    }
  };