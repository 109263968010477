import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import {
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
export default function DriverEarning() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const countrylistdata = useSelector(state => state.countrylistdata);
  const columns =  [
      { title: t('year'),field: 'year'},
      { title: t('months'), field: 'monthsName', },
      { title: t('country'), field: 'country', },
      { title: t('driver_name'), field: 'driverName',},
      { title: t('booking_count'), field: 'total_rides', },
      { title: t('vehicle_reg_no'), field: 'driverVehicleNo', },
      { title: t('earning_amount'), field: 'driverShare', }
  ];
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const driverearningdata = useSelector(state => state.driverearningdata);
  const [selectedCountry, setSelectedCountry] = useState(t('select_country'));
  const [selectedRow, setSelectedRow] = useState(null);
  useEffect(()=>{
        if(driverearningdata.driverearnings){
            setData(driverearningdata.driverearnings);
        }
  },[driverearningdata.driverearnings]);

  useEffect(() => {
    if (countrylistdata.countries) {
      setCountries(countrylistdata.countries);
    }else{
      setCountries([]);
    }
  }, [countrylistdata.countries]);

  const handleCountrySelect = (event) => {
    let arr =[];
    if(driverearningdata.driverearnings){
        for(let i=0; i < driverearningdata.driverearnings.length; i++){
          if(driverearningdata.driverearnings[i].country === event.target.value){
            arr.push(driverearningdata.driverearnings[i])
          }
        }
    }
    setData(arr);
    setSelectedCountry(event.target.value);
}

  return (
    driverearningdata.loading? <CircularLoading/>:
    <div>
   <FormControl sx={{ m: 1, width: 300 }}>
       <Select
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={selectedCountry}
         onChange={handleCountrySelect}
         style={{textAlign:isRTL==='rtl'? 'right':'left',border:'1px solid #9E9E9E'}}
       >
               <MenuItem disabled value={t('select_country')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
            {t('select_country')}
          </MenuItem>
             {countries.map((item) =>
             <MenuItem dense={true}  key={item.country} value={item.country_code} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
             {t(item.country)}</MenuItem>)}</Select>
    </FormControl>
    <MaterialTable
      title={t('driver_earning')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        exportButton: true,
        grouping: true,
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
        }),
        editable:{
          backgroundColor: colors.WHITE,
          fontSize: "0.8em",
          fontWeight: 'bold ',
          fontFamily: 'Lucida Console", "Courier New", monospace'
        },
        cellStyle:{ textAlign: 'center'},
        headerStyle: {
          position: "sticky",
          textAlign:'center',
          top: "0px",
          backgroundColor: colors.SECONDARY ,
          color: colors.WHITE,
          fontSize: "0.8em",
          fontWeight: 'bold ',
          fontFamily: 'Lucida Console", "Courier New", monospace'
        }
      }}
      localization={{
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        pagination: {
          labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
          firstTooltip: (t('first_page_tooltip')),
          previousTooltip: (t('previous_page_tooltip')),
          nextTooltip: (t('next_page_tooltip')),
          lastTooltip: (t('last_page_tooltip'))
        },
      }}
    />
    </div>
  );
}
