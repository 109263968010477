import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import CountryListSelect from '../components/CountryListSelect';

export default function Countries() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    countries,
    editCountry
  } = api;

  const auth = useSelector(state => state.auth);
  const columns = [
      { title: t('country'), field: 'country', 
      editComponent: props => (
        <CountryListSelect
            label={t('select_country')}
            dis={true}
            countries={countries}
            onlyCode={false}
            onChange={
                (object, value) => {
                    props.onChange(value.label)
                }
            }
        />
      ),
    },
    { title: t('code'), field: 'country_code', editable: 'never'},
    { title: t('phone_code'), field: 'phone_code', editable: 'never'},
    { title: t('currency_symbol'), field: 'symbol'},
    { title: t('currency_code'), field: 'currency_code'},
    { title: t('swipe_symbol'), field: 'swipe_symbol', type: 'boolean', initialEditValue: false},
    { title: t('position'), field: 'pos', type: 'numeric'},
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const countrylistdata = useSelector(state => state.countrylistdata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (countrylistdata.countries) {
      setData(countrylistdata.countries);
    }else{
      setData([]);
    }
  }, [countrylistdata.countries]);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    countrylistdata.loading ? <CircularLoading /> :
      <MaterialTable
        title={t('countries')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          exportButton: true,
          pageSize: 10,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
          }),
          editable:{
            backgroundColor: colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace'
          },
          cellStyle:{ textAlign: 'center'},
          headerStyle: {
            position: "sticky",
            textAlign:'center',
            top: "0px",
            backgroundColor: colors.SECONDARY ,
            color:colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace'
          },
        }}
        localization={{body:{
          addTooltip: (t('add')),
          deleteTooltip: (t('delete')),
          editTooltip: (t('edit')),
          emptyDataSourceMessage: (
            (t('blank_message'))
        ),
        editRow: { 
          deleteText: (t('delete_message')),
          cancelTooltip: (t('cancel')),
          saveTooltip: (t('save')) 
            }, 
          },
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
            new Promise((resolve, reject)=> {
              setTimeout(() => {
                if(!(newData && newData.country && newData.symbol && newData.symbol.length>0 && newData.currency_code && newData.currency_code.length > 0)){
                  alert(t('no_details_error'));
                  reject();
                }else{
                  for(let i=0;i<countries.length;i++){
                    if(newData.country === countries[i].label){
                      newData.country_code = countries[i].code;
                      newData.phone_code = countries[i].phone;
                    }
                  }
                  dispatch(editCountry(newData, "Add"));
                  resolve();
                }
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject)=> {
              setTimeout(() => {
                if(!(newData && newData.country && newData.symbol && newData.symbol.length>0 && newData.currency_code && newData.currency_code.length > 0)){
                  alert(t('no_details_error'));
                  reject();
                }else {
                  for(let i=0;i<countries.length;i++){
                    if(newData.country === countries[i].label){
                      newData.country_code = countries[i].code;
                      newData.phone_code = countries[i].phone;
                    }
                  }
                  dispatch(editCountry(newData, "Update"));
                  resolve()
                }
              }, 600);
            }),
          onRowDelete: oldData =>
            auth && auth.profile && auth.profile.usertype !== 'subadmin' ?
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  dispatch(editCountry(oldData, "Delete"));
                }, 600);
              })
            :
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                alert(t('restricted_for_sub_admin'));
              }, 600);
            })
        } : null}
      />
  );
}
