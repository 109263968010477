export const FirebaseConfig = {
	"projectId": "vum-ride",
	"appId": "1:214426735349:web:743da79727ec4cbab1ebbc",
	"databaseURL": "https://vum-ride-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "vum-ride.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyA6bKIM7O8HsUjFi0fgkf75TaUyxDzOd5w",
	"authDomain": "vum-ride.firebaseapp.com",
	"messagingSenderId": "214426735349",
	"measurementId": "G-HE65GX98F0"
};