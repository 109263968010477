import {
    UPDATE_USER_WALLET_HISTORY,
} from "../store/types";

import { firebase } from '../config/configureFirebase';
import { onValue,off } from "firebase/database";

export const fetchWalletHistory = () => (dispatch) => {
    const {
      auth,
      walletHistoryRef
    } = firebase;
  
    const uid = auth.currentUser.uid;
  
    off(walletHistoryRef(uid));
    onValue(walletHistoryRef(uid) , snapshot => {
      const data = snapshot.val(); 
      if(data){
        const arr = Object.keys(data).map(i => {
          data[i].id = i
          return data[i]
        });
        dispatch({
          type: UPDATE_USER_WALLET_HISTORY,
          payload: arr.reverse()
        });
      } else {
        dispatch({
          type: UPDATE_USER_WALLET_HISTORY,
          payload: []
        });
      }
    });
  };