import React, { useState, useEffect } from 'react';
import DashboardCard from '../components/DashboardCard';
import {GoogleMap,Marker,InfoWindow} from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import Chart from 'react-apexcharts';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { colors } from 'components/Theme/WebTheme';
import { api } from 'common';
import {Select,MenuItem,FormControl,Box,Typography} from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
const Dashboard = () => {
    const [mylocation, setMylocation] = useState(null);
    const [locations, setLocations] = useState([]);
    const [dailygross, setDailygross] = useState(0);
    const [monthlygross, setMonthlygross] = useState(0);
    const [totalgross, setTotalgross] = useState(0);
    const [settings, setSettings] = useState({});
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const usersdata = useSelector(state => state.usersdata);
    const bookinglistdata = useSelector(state => state.bookinglistdata);
    const settingsdata = useSelector(state => state.settingsdata);
    const auth = useSelector(state => state.auth);
    const cars = useSelector(state => state.cartypes.cars);
    const [allCompleteCount, setAllCompleteCount] = useState([]);
    const [allCancelCount, setAllCancelCount] = useState([]);
    const [activeCount, setActiveCount] = useState(0);
    const [driverCount, setDriverCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);
    const dispatch = useDispatch();
    const countrylistdata = useSelector(state => state.countrylistdata);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(t('select_country'));
    const { fetchDrivers, clearFetchDrivers } = api;
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(fetchDrivers('web'));
        return () => {
            dispatch(clearFetchDrivers());
        };
    }, [dispatch, fetchDrivers, clearFetchDrivers]);

    useEffect(() => {
        if (mylocation == null) {
            navigator.geolocation.getCurrentPosition(
                position => setMylocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }),
                error => console.log(error)
            );
        }
    }, [mylocation]);


    useEffect(() => {
        if (countrylistdata.countries) {
            setCountries(countrylistdata.countries);
        } else {
            setCountries([]);
        }
        let country = countrylistdata.countries;
        for (let i =0; i< country.length ; i++) {
            if(country[i].pos === 0 ){
                setSelectedCountry(country[i].country_code)
            }
        }
    }, [countrylistdata.countries]);

    useEffect(()=>{
        if(countrylistdata.countries){
        let country = countrylistdata.countries;
        for (let i =0; i< country.length ; i++) {
            if(selectedCountry === country[i].country_code){
                setData(country[i])
            }
        }
    }
  },[selectedCountry, countrylistdata.countries]);

    useEffect(() => {
        if (settingsdata.settings) {
            setSettings(settingsdata.settings);
        }
    }, [settingsdata.settings]);

    useEffect(() => {
        if (usersdata.drivers && bookinglistdata.bookings) {
            const liveBookings = bookinglistdata.bookings.filter(bkg => bkg.status === 'STARTED');
            const drivers = usersdata.drivers;
            let locs = [];
            for (let i = 0; i < drivers.length; i++) {
                if (drivers[i].location) {
                    let carImage = 'https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png';
                    let bookingRef = null;
                    for (let j = 0; j < cars.length; j++) {
                        if (cars[j].name === drivers[i].carType) {
                            carImage = cars[j].image;
                        }
                    }
                    for (let j = 0; j < liveBookings.length; j++) {
                        if (liveBookings[j].driver === drivers[i].uid) {
                            bookingRef = liveBookings[j].reference;
                        }
                    }
                    locs.push({
                        id: i,
                        lat: drivers[i].location.lat,
                        lng: drivers[i].location.lng,
                        drivername: drivers[i].firstName + ' ' + drivers[i].lastName,
                        carnumber: drivers[i].vehicleNumber,
                        cartype: drivers[i].carType,
                        carImage: carImage,
                        bookingRef: bookingRef
                    });
                }
            }
            setLocations(locs);
        }
    }, [usersdata.drivers, auth.profile.usertype, auth.profile.uid, cars, bookinglistdata.bookings]);

    const handleCountrySelect = (event) => {
        let arr = [];
        if (bookinglistdata.bookings) {
            for (let i = 0; i < bookinglistdata.bookings.length; i++) {
                if (bookinglistdata.bookings[i].country === event.target.value) {
                    arr.push(bookinglistdata.bookings[i])
                }
            }
        }
        setSelectedCountry(event.target.value);
    }

    useEffect(() => {
        if (bookinglistdata.bookings) {
            let today = new Date();
            let monthlyTotal = 0;
            let yearlyTotal = 0;
            let todayTotal = 0;
            for (let i = 0; i < bookinglistdata.bookings.length; i++) {
                if ((bookinglistdata.bookings[i].status === 'PAID' || bookinglistdata.bookings[i].status === 'COMPLETE') &&  bookinglistdata.bookings[i].country === selectedCountry&&  ((bookinglistdata.bookings[i].fleetadmin === auth.profile.uid && auth.profile.usertype === 'fleetadmin') || auth.profile.usertype === 'admin' || auth.profile.usertype === 'subadmin')) {
                    const { tripdate, convenience_fees, fleetCommission, discount } = bookinglistdata.bookings[i];
                    let tDate = new Date(tripdate);
                    if (convenience_fees && parseFloat(convenience_fees) > 0 && (auth.profile.usertype === 'admin' || auth.profile.usertype === 'subadmin')) {
                        if (tDate.getDate() === today.getDate() && tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            todayTotal = (todayTotal + parseFloat(convenience_fees) - parseFloat(discount));
                        }
                        if (tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            monthlyTotal = (monthlyTotal + parseFloat(convenience_fees) - parseFloat(discount));
                        }
                        yearlyTotal = (yearlyTotal + parseFloat(convenience_fees) - parseFloat(discount));
                    }
                    if (fleetCommission && parseFloat(fleetCommission) > 0 && auth.profile.usertype === 'fleetadmin') {
                        if (tDate.getDate() === today.getDate() && tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            todayTotal = (todayTotal + parseFloat(fleetCommission));
                        }
                        if (tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            monthlyTotal = (monthlyTotal + parseFloat(fleetCommission));
                        }
                        yearlyTotal = (yearlyTotal + parseFloat(fleetCommission));
                    }
                }
            }
            setDailygross(parseFloat(todayTotal).toFixed(settings.decimal));
            setMonthlygross(parseFloat(monthlyTotal).toFixed(settings.decimal));
            setTotalgross(parseFloat(yearlyTotal).toFixed(settings.decimal));
        }
    }, [bookinglistdata.bookings, settings, auth.profile.uid, auth.profile.usertype, selectedCountry]);

    const barChartOptions = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        colors: ["#088395", "#ea969d"],
        options: {
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
        },
    };

    const barChart = [{
        name: t('completed_bookings'),
        data: allCompleteCount
    }, {
        name: t('cancelled_bookings'),
        data: allCancelCount
    }];
    useEffect(() => {
        if (usersdata.users) {
            let driverCount = 0;
            let activeCount = 0;
            let customerCount = 0;
            for (let i = 0; i < usersdata.users.length; i++) {
                if (usersdata.users[i].usertype === 'driver') {
                    if ((auth.profile.usertype === 'fleetadmin' && usersdata.users[i].fleetadmin === auth.profile.uid) || auth.profile.usertype === 'admin' || auth.profile.usertype === 'subadmin') {
                        driverCount = driverCount + 1;
                    }
                }
                if (usersdata.users[i].driverActiveStatus === true) {
                    if ((auth.profile.usertype === 'fleetadmin' && usersdata.users[i].fleetadmin === auth.profile.uid) || auth.profile.usertype === 'admin' || auth.profile.usertype === 'subadmin') {
                        activeCount = activeCount + 1;
                    }
                }
                if (usersdata.users[i].usertype === 'customer') {
                    if ((auth.profile.usertype === 'fleetadmin' && usersdata.users[i].fleetadmin === auth.profile.uid) || auth.profile.usertype === 'admin' || auth.profile.usertype === 'subadmin') {
                        customerCount = customerCount + 1;
                    }
                }
            }
            setActiveCount(activeCount);
            setDriverCount(driverCount);
            setCustomerCount(customerCount);
        } else {
            setActiveCount(0)
            setDriverCount(0)
            setCustomerCount(0)
        }
    }, [usersdata.users, auth.profile]);

    useEffect(() => {
        let allCompleteCount = [];
        let allCancelCount = [];
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (bookinglistdata.bookings) {
            for (let i = 0; i < months.length; i++) {
                let completeCount = 0;
                let cancelCount = 0;
                for (let j = 0; j < bookinglistdata.bookings.length; j++) {
                    const { tripdate } = bookinglistdata.bookings[j];
                    let tDate = new Date(tripdate);
                    if (months[i] === months[tDate.getMonth()] && (bookinglistdata.bookings[j].status === 'PAID' || bookinglistdata.bookings[j].status === 'COMPLETE')) {
                        completeCount = completeCount + 1;
                    }
                    if (months[i] === months[tDate.getMonth()] && bookinglistdata.bookings[j].status === 'CANCELLED') {
                        cancelCount = cancelCount + 1;
                    }
                }
                allCompleteCount.push(completeCount);
                allCancelCount.push(cancelCount);
            }
        }
        setAllCompleteCount(allCompleteCount);
        setAllCancelCount(allCancelCount);
    }, [bookinglistdata.bookings]);

    return (
        bookinglistdata.loading || usersdata.loading ? <CircularLoading /> :
            <div>
                <Grid container direction="row" spacing={2} style={{ marginTop:25,}}>
                    <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left'}}>
                        <Card style={{ borderRadius: "19px",boxShadow:6 ,backgroundColor: '#394867', minHeight: 120, padding: 28, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <GroupsOutlinedIcon style={{ color: 'white', fontSize: 52 }} />
                            <div>
                                <Typography style={{ color: colors.WHITE, textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>{customerCount}</Typography>
                                <Typography style={{ color: colors.WHITE, textAlign: 'center', fontSize: 20, }}>{t('total_cumtomer')}</Typography>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
                    <Card style={{ borderRadius: "19px",boxShadow:6, backgroundColor: '#526D82', minHeight: 120, padding: 28, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <GroupsOutlinedIcon style={{ color: 'white', fontSize: 52 }} />
                            <div>
                            <Typography style={{ color: colors.WHITE, textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>{driverCount}</Typography>
                            <Typography style={{ color: colors.WHITE, textAlign: 'center', fontSize: 20,}}>{t('total_drivers')}</Typography>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
                    <Card style={{ borderRadius: "19px",boxShadow:6, backgroundColor: '#088395', minHeight: 120, padding: 28, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <PeopleAltOutlinedIcon style={{ color: 'white', fontSize: 42 }} />
                            <div>
                            <Typography style={{ color: colors.WHITE, textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>{activeCount}</Typography>
                            <Typography style={{ color: colors.WHITE, textAlign: 'center', fontSize: 20 }}>{t('active_driver')}</Typography>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} >
                        <Grid item xs={12} sm={12} md={9} lg={9} style={{ backgroundColor: colors.SECONDARY, borderRadius: 20, overflow: 'hidden', }} boxShadow={3}>
                            <Typography variant="h5" style={{ color: colors.WHITE, margin: "20px 20px 0 15px", textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>{t('real_time_driver_section_text')}</Typography>
                            {mylocation && mylocation.lat ?
                                <GoogleMap
                                    zoom={10}
                                    center={mylocation}
                                    mapContainerStyle={{ height: `528px` }}
                                >
                                    {locations.map(marker => (
                                        <Marker
                                            position={{ lat: marker.lat, lng: marker.lng }}
                                            key={marker.id}
                                            icon={{
                                                url: marker.carImage,
                                                scaledSize: new window.google.maps.Size(35, 25)
                                            }}

                                        >
                                            <InfoWindow
                                                position={{ lat: marker.lat, lng: marker.lng }}
                                                options={{ pixelOffset: new window.google.maps.Size(0, -32) }}
                                            >
                                                <>{marker.drivername}<br />{marker.carnumber ? t('vehicle_no') + ': ' + marker.carnumber : null}<br />{marker.bookingRef ? t('booking_ref') + marker.bookingRef : null}</>
                                            </InfoWindow>

                                        </Marker>
                                    ))}
                                </GoogleMap>
                                : null}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} style={{ padding: 15 }}>
                            <Typography variant="h4" style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>{t('gross_earning')}</Typography>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedCountry}
                                    onChange={handleCountrySelect}
                                    style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E' }}
                                >
                                    <MenuItem disabled value={t('select_country')} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                        {t('select_country')}
                                    </MenuItem>
                                    {countries.map((item) =>
                                        <MenuItem dense={true} key={item.country} value={item.country_code} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', width: '100%', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            {t(item.country)}</MenuItem>)}</Select>
                            </FormControl>
                            <Grid container direction="row" spacing={2} style={{ padding: 15 }}>
                                <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                                    {data.swipe_symbol === false ?
                                        <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#394867', minHeight: 120 }} title={t('today_text')} image={require("../assets/img/money1.jpg").default}>{data.symbol + ' ' + dailygross}</DashboardCard>
                                        :
                                        <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#394867', }} title={t('today_text')} image={require("../assets/img/money1.jpg").default}>{dailygross + ' ' + data.symbol}</DashboardCard>
                                    }
                                </Grid>
                                <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                                    {data.swipe_symbol === false ?
                                        <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#526D82', minHeight: 120 }} title={t('this_month_text')} image={require("../assets/img/money2.jpg").default}>{data.symbol + ' ' + monthlygross}</DashboardCard>
                                        :
                                        <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#526D82' }} title={t('this_month_text')} image={require("../assets/img/money2.jpg").default}>{monthlygross + ' ' + data.symbol}</DashboardCard>
                                    }
                                </Grid>
                                <Grid item xs style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                                    {data.swipe_symbol === false ?
                                        <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#088395', minHeight: 120 }} title={t('total')} image={require("../assets/img/money3.jpg").default}>{data.symbol + ' ' + totalgross}</DashboardCard>
                                        :
                                        <DashboardCard crdStyle={{ display: 'flex', borderRadius: "19px", backgroundColor: '#088395', }} title={t('total')} image={require("../assets/img/money3.jpg").default}>{totalgross + ' ' + data.symbol}</DashboardCard>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>
                <Box style={{ padding: 10, marginTop: 30, borderRadius: 20, backgroundColor: colors.WHITE }} boxShadow={6}>
                    <Typography style={{ margin: "5px 20px 0 15px", color: colors.MAIN_COLOR, }} variant="h5">{t('booking_chart')}</Typography>
                    <Chart
                        options={barChartOptions}
                        series={barChart}
                        type="area"
                        width="100%"
                        height={360}
                    />
                </Box>
            </div>

    )
}

export default Dashboard;