import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import TextField from '@mui/material/TextField';

export default function PaymentSettings() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editPaymentMethods
  } = api;

  const columns = [
    { title: t('name'), field: 'name', editable: 'never', render: rowData => <span>{rowData.name}</span>,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},
    { title: t('public_key'), field: 'public_key', render: rowData => rowData.public_key && rowData.public_key.length >1 ? <span>{"*****************"}</span> : "",
    editComponent: props => (
      <TextField
          label={t('public_key')}
          onChange={
              (e) => {
                  props.onChange(e.target.value)
              }
          }
      />
    ),},
    { title: t('secret_key'), field: 'secret_key',render: rowData => rowData.secret_key && rowData.secret_key.length >1 ? <span>{"*****************"}</span> : "",
    editComponent: props => (
      <TextField
          label={t('secret_key')}
          onChange={
              (e) => {
                  props.onChange(e.target.value)
              }
          }
      />
    ),},
    { title: t('testing'), field: 'testing',type:'boolean'},
    { title: t('active'), field: 'active', type:'boolean'}
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const paymentmethods = useSelector(state => state.paymentmethods);
  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentmethods.providers) {
      setData(paymentmethods.providers);
    }else{
      setData([]);
    }
  }, [paymentmethods.providers]);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    paymentmethods.loading ? <CircularLoading /> :
      <MaterialTable
        title={t('payment_settings')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          exportButton: true,
          pageSize: 10,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
          }),
          editable:{
            backgroundColor: colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace'
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: colors.SECONDARY ,
            color:  colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace'
          },
        }}
        localization={{body:{
          addTooltip: (t('add')),
          deleteTooltip: (t('delete')),
          editTooltip: (t('edit')),
          emptyDataSourceMessage: (
            (t('blank_message'))
        ),
        editRow: { 
          deleteText: (t('delete_message')),
          cancelTooltip: (t('cancel')),
          saveTooltip: (t('save')) 
            }, 
          },
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject)=> {
              setTimeout(() => {
                  resolve();
                  delete newData.tableData;
                  let activePay = null;
                  for(let i=0;i<data.length;i++){
                    if(data[i].withdraw_enable === newData.withdraw_enable && data[i].withdraw_enable){
                      activePay = data[i];
                      break;
                    }
                  }
                  if(activePay && newData.withdraw_enable){
                    activePay.withdraw_enable = false;
                    dispatch(editPaymentMethods(activePay));
                  }
                  dispatch(editPaymentMethods(newData));
                  
              }, 600);
            }),
        } : null}
      />
  );
}
